<template>
  <div>
    <!-- 水平方向菜单（宽屏模式下显示） -->
    <el-menu
      v-if="isWideScreen"
      :class="['horizontal-menu', { 'scrolled': isScrolled }]" 
      mode="horizontal"
    >
      <!-- Logo -->
<el-menu-item class="logo-menu-item" style="display: flex; align-items: center;">
  <img src="@/assets/logo.png" class="logo" style="height: 40px; max-width: 40px; margin-right: 10px;" />
  <span style="font-size: 24px;">科岚宁专业测试</span>
</el-menu-item>


      <!-- 菜单项 -->
      <el-menu-item
        v-for="item in menuItems"
        :key="item.name"
        @click="navigate(item.path)"
        style="cursor: pointer;"
      >
        {{ item.name }}
      </el-menu-item>
         
    </el-menu>

    <!-- 抽屉菜单（窄屏模式下显示） -->
    <el-drawer
      v-if="!isWideScreen"
      title="菜单"
      :visible.sync="isDrawerVisible"
      direction="ltr"
      custom-class="drawer-menu"
    >
      <el-menu class="sidebar-menu">
        <el-menu-item
          v-for="item in menuItems"
          :key="item.name"
          @click="navigate(item.path)"
        >
          {{ item.name }}
        </el-menu-item>
      </el-menu>
    </el-drawer>

    <!-- 抽屉开关按钮（窄屏模式下显示） -->
    <el-button class="menu-toggle" v-if="!isWideScreen" @click="toggleDrawer">
      <i class="el-icon-menu"></i>
    </el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuItems: [
        { name: '首页', path: '/' },
        { name: '材料测试', path: '/materials' },
        { name: '模拟计算', path: '/simulation' },
        { name: '试剂耗材', path: '/reagents' },
        { name: '仪器设备', path: '/equipment' },
        { name: '科研绘图', path: '/graphics' },
        { name: '关于我们', path: '/about' },
      ],
      isDrawerVisible: false,
      isWideScreen: window.innerWidth > 768, // 初始化宽度检测
      isScrolled: false, // 用于判断是否滚动
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll); // 监听滚动事件
    this.handleResize();
    this.handleScroll();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll); // 移除滚动事件监听
  },
  methods: {
    handleResize() {
      const isWide = window.innerWidth > 768;
      this.isWideScreen = isWide;
    },
    handleScroll() {
      // 判断页面滚动距离，改变背景色
      this.isScrolled = window.scrollY > 0;
    },
    toggleDrawer() {
      this.isDrawerVisible = !this.isDrawerVisible;
    },
    closeDrawer() {
      this.isDrawerVisible = false;
    },
    navigate(path) {
  if (this.$route.path !== path) {
    this.$router.push(path); // 使用 Vue Router 进行页面跳转
    window.scrollTo(0, 0);
  }
}

  },
};
</script>
<style scoped>
/* 设置logo大小 */
.logo {
  width: 180px;
  height: 40px;
}

/* 水平菜单样式 */
.horizontal-menu {
  display: flex;
  background-color: #00102e; /* 背景颜色 */
  color: white;
  height: 60px; /* 设置统一高度 */
  line-height: 60px; /* 文字垂直居中 */
  position: fixed; /* 固定悬浮 */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* 确保菜单悬浮在上层 */
  transition: background-color 0.3s ease; /* 平滑过渡效果 */
  border: none; /* 去除菜单的下边框 */
  box-shadow: none; /* 去除底部阴影 */
}

/* 水平菜单项样式 */
.horizontal-menu .el-menu-item {
  height: 60px; /* 菜单项高度 */
  line-height: 60px; /* 文字垂直居中 */
  color: white; /* 设置字体颜色为白色 */
  text-decoration: none; /* 去除下划线 */
  border-bottom: none; /* 去除菜单项下方的白色线条 */
  padding: 0 20px; /* 水平内边距 */
}
.el-menu--horizontal {
    border-bottom: none !important;
  }
.sidebar-menu {
  border: none !important;
}

/* 鼠标悬停时的颜色（不改变） */
.horizontal-menu .el-menu-item:hover {
  background-color: #f1f1f1; /* 可选：保持现有悬浮颜色 */
}

/* 禁用 Logo 所在的菜单项 */
.logo-menu-item {
  pointer-events: none; /* 禁止点击 */
}

/* 抽屉菜单 */
.sidebar-menu {
  background-color: #00102e;
  color: white;
}
/* 抽屉标题样式：字体变大，颜色为白色 */
::v-deep .el-drawer__header {
  font-size: 16px !important; /* 调整字体大小 */
  color: white !important;    /* 设置字体颜色为白色 */
}

/* 抽屉菜单整体背景和字体颜色 */
::v-deep .el-drawer {
  background-color: #00102e !important; /* 背景色 */
  color: white !important; /* 字体颜色 */
}

/* 抽屉菜单中的菜单项字体颜色 */
::v-deep .sidebar-menu .el-menu-item {
  color: white !important; /* 强制字体颜色 */
}

.drawer-menu {
  background-color: #00102e !important; /* 强制设置背景色 */
  color: white !important; /* 可选：调整字体颜色，确保文字可见 */
}


/* 窄屏下的菜单切换按钮 */
.menu-toggle {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  color: white;
  background-color: #00102e;
  border: none;
}

/* 去除水平菜单中 router-link 的下划线 */
.horizontal-menu .el-menu-item a {
  text-decoration: none;
  color: white;
}

/* 去除抽屉菜单中 router-link 的下划线 */
.sidebar-menu .el-menu-item a {
  text-decoration: none;
  color: white;
}

.logo-menu-item:hover {
  background-color: transparent !important;
  color: inherit !important;
}
.horizontal-menu .el-button {
  margin-left: auto; /* 将按钮推到最右侧 */
}
</style>
