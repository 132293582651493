<template>
  <div class="float-buttons">
    <!-- 滚动到顶部按钮 -->
    <div class="button-container">
      <el-button class="float-button" type="primary" icon="el-icon-caret-top" @click="scrollToTop"></el-button>
    </div>

    <!-- 点击扫码联系客服按钮 -->
    <div class="button-container">
      <el-tooltip effect="dark" content="点击扫码联系客服" placement="left">
        <el-button class="float-button" type="primary" icon="el-icon-chat-dot-round" @click="toggleQrCode"></el-button>
      </el-tooltip>
    </div>

    <!-- 二维码（根据 showQrCode 控制显示/隐藏） -->
    <img v-if="showQrCode" src="@/assets/wechat1.png" class="qr-code" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      showQrCode: false, // 二维码初始为隐藏
    };
  },
  methods: {
    // 滚动到页面顶部
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    // 切换二维码的显示状态
    toggleQrCode() {
      this.showQrCode = !this.showQrCode;
    },
  },
};
</script>
<style scoped>
/* 悬浮按钮容器样式 */
.float-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 1000; /* 保证悬浮按钮在页面上层 */
}

/* 按钮容器样式 */
.button-container {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

/* 按钮样式 */
.float-button {
  width: 100%; /* 占满容器宽度 */
  height: 100%; /* 占满容器高度 */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

/* 二维码样式 */
.qr-code {
  width: 100px; /* 二维码宽度 */
  position: absolute;
  bottom: 60px; /* 调整位置避免按钮重叠 */
  right: 0;
}
</style>
