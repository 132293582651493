<template>
  <div id="app" >
    <Header />
    <router-view />
    <Footer />
    <BackToTopAndContact />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import BackToTopAndContact from './components/BackToTopAndContact.vue';

export default {
  components: {
    Header,
    Footer,
    BackToTopAndContact,
  },
};
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
/* 针对所有滚动条 */
::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}



</style>
