<template>
  <div>
    <p style="height: 60px;"></p>
    <!-- 第一个走马灯 -->
    <div class="carousel-container first-carousel">
      <div class="carousel-title">期刊封面(示例)</div>
      <el-carousel :interval="5000" arrow="always" type="card">
        <el-carousel-item v-for="(item, index) in carouselImages1" :key="index">
          <img :src="item.src" alt="Journal Illustrations" @click="openModal(item.src)" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 第二个走马灯 -->
    <div class="carousel-container">
      <div class="carousel-title">论文插图</div>
      <el-carousel :interval="5000" arrow="always" type="card">
        <el-carousel-item v-for="(item, index) in carouselImages2" :key="index">
          <img :src="item.src" alt="Thesis Illustration" @click="openModal(item.src)" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 流程线 -->
    <el-timeline class="timeline-container">
      <div class="carousel-title">服务流程</div>
      <el-timeline-item 
        v-for="(step, index) in serviceProcess" 
        :key="index" 
        :icon="step.icon" 
        :color="step.color"
        :size="step.size" 
        :timestamp="step.timestamp">
        {{ step.description }}
      </el-timeline-item>
    </el-timeline>

    <!-- 模态框 -->
    <el-dialog :visible.sync="isModalVisible" width="70%" @close="closeModal">
      <img :src="modalImageSrc" alt="Enlarged Image" class="modal-image" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 第一个走马灯的数据
      carouselImages1: [
        { src: require('@/assets/graphics/1.jpg') },
        { src: require('@/assets/graphics/2.jpg') },
        { src: require('@/assets/graphics/3.jpg') },
        { src: require('@/assets/graphics/4.jpg') }
      ],
      // 第二个走马灯的数据
      carouselImages2: [
        { src: require('@/assets/graphics/illustration/1.jpg') },
        { src: require('@/assets/graphics/illustration/2.jpg') },
        { src: require('@/assets/graphics/illustration/3.jpg') },
        { src: require('@/assets/graphics/illustration/4.jpg') },
        { src: require('@/assets/graphics/illustration/5.jpg') },
        { src: require('@/assets/graphics/illustration/6.jpg') },
        { src: require('@/assets/graphics/illustration/7.jpg') },
        { src: require('@/assets/graphics/illustration/8.jpg') },
        { src: require('@/assets/graphics/illustration/9.jpg') },
        { src: require('@/assets/graphics/illustration/10.jpg') },
        { src: require('@/assets/graphics/illustration/11.jpg') },
        { src: require('@/assets/graphics/illustration/12.jpg') },
        { src: require('@/assets/graphics/illustration/13.jpg') },
      ],
      // 流程线的数据，包含图标、颜色、尺寸和时间戳等
      serviceProcess: [
        { 
          description: '1·需求提交: 填写需求并提交，准备好详细介绍文字、草图、参考图等', 
          timestamp: '', 
          icon: 'el-icon-loading', 
          color: '#4CAF50', 
          size: 'large' 
        },
        { 
          description: '2·沟通细节: 与绘图老师确认需求、时间、价格等具体事项', 
          timestamp: '', 
          icon: 'el-icon-message', 
          color: '#FF5722', 
          size: 'default' 
        },
        { 
          description: '3·付款确认: 请联系客服确认订单报价并付款', 
          timestamp: '', 
          icon: 'el-icon-goods', 
          color: '#FFC107', 
          size: 'default' 
        },
        { 
          description: '4·初稿确认: 付款后3-7个工作日给出初稿，确认无误后正式作图', 
          timestamp: '', 
          icon: 'el-icon-location-outline', 
          color: '#009688', 
          size: 'large' 
        },
        { 
          description: '5·定稿交付: 终稿出炉后，可免费修改到您满意为止！（不对图形基本结构作大修改）', 
          timestamp: '', 
          icon: 'el-icon-check', 
          color: '#673AB7', 
          size: 'default' 
        },
        { 
          description: '6·发票清单: 支付发票和服务清单我们统计后会通过邮件或邮寄的方式发送', 
          timestamp: '', 
          icon: 'el-icon-paperclip', 
          color: '#3F51B5', 
          size: 'default' 
        }
      ],
      // 模态框控制变量
      isModalVisible: false,
      modalImageSrc: '' // 当前显示的放大图片地址
    };
  },
  methods: {
    // 打开模态框并显示放大的图片
    openModal(imageSrc) {
      this.modalImageSrc = imageSrc;
      this.isModalVisible = true;
    },
    // 关闭模态框
    closeModal() {
      this.isModalVisible = false;
    }
  }
};
</script>

<style scoped>
.carousel-container {
  text-align: center;
  margin-bottom: 30px;
  width: 80%; /* 设置宽度为屏幕的80%，不占满整个屏幕 */
  margin-left: auto;
  margin-right: auto; /* 居中显示 */
}

.carousel-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.carousel-title::after {
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

/* 增大第一个走马灯的高度 */
.first-carousel .el-carousel__item img {
  width: 100%; /* 宽度填满容器 */
  height: 100%; /* 高度自适应 */
  max-height: 1000px; /* 设置最大高度为900px */
  object-fit: contain; /* 保持图片的长宽比例，避免图片拉伸变形 */
}

/* 第二个走马灯的高度保持不变 */
.el-carousel__item img {
  width: 100%;
  height: auto;
  max-height: 500px; /* 设置第二个走马灯的最大高度为500px */
  object-fit: contain;
}

/* 模态框中的放大图片 */
.modal-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

/* 服务流程部分的居中样式 */
.timeline-container {
  text-align: center; /* 将整个流程线居中 */
  margin-top: 20px;
}

.timeline-item {
  display: inline-block; /* 使每个时间节点项目行内显示 */
  text-align: center;  /* 每个时间节点的内容居中 */
  width: 100%; /* 使每个项的宽度为100% */
}

.timeline-item div {
  display: inline-block;
  text-align: center;
}

/* 修改时间线左侧的样式为绿色 */
.el-timeline-item__tail {
  background-color: #4CAF50 !important; /* 更改连接线为绿色 */
}

.el-timeline-item__dot {
  background-color: #4CAF50 !important; /* 更改圆点颜色为绿色 */
}

.el-timeline-item__content {
  color: #333;
  font-size: 16px;
  font-weight: bold;
}
</style>
