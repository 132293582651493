import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';
import Materials from '../views/Materials.vue';
import Simulation from '../views/Simulation.vue';
import Reagents from '../views/Reagents.vue';
import Equipment from '../views/Equipment.vue';
import Graphics from '../views/Graphics.vue';
import About from '../views/About.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',  // 使用 HTML5 history 模式，不使用 #
  routes: [
    { path: '/', name: 'Home', component: Home },
    { path: '/materials', name: 'Materials', component: Materials },
    { path: '/simulation', name: 'Simulation', component: Simulation },
    { path: '/reagents', name: 'Reagents', component: Reagents },
    { path: '/equipment', name: 'Equipment', component: Equipment },
    { path: '/graphics', name: 'Graphics', component: Graphics },
    { path: '/about', name: 'About', component: About },
  ],
});
