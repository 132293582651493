<template>
  <div>
    <p style="height: 60px;"></p>
    <!-- 控制宽度的 div -->
    <div style="max-width: 90vw; margin: 0 auto;">
      <!-- 循环各个分类 -->
      <div v-for="(category, index) in categories" :key="index">
        <h2 class="category-title">{{ category.title }}</h2>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="6" v-for="(reagent, index) in category.products" :key="index">
            <el-card shadow="hover" class="product-card">
              <!-- 点击图片查看大图 -->
              <img
                :src="require(`@/assets/reagents/${category.imageFolder}/${reagent.image}`)"
                :alt="reagent.name"
                class="product-image"
                @click="openImageViewer(require(`@/assets/reagents/${category.imageFolder}/${reagent.image}`))"
              />
              <div class="product-info">
                <h3>{{ reagent.name }}</h3>
                <div class="price-wrapper">
                  <span class="price">{{ reagent.price || '价格待定' }}</span>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 图片查看器 -->
    <el-dialog
      v-model="imageViewerVisible"
      :visible.sync="imageViewerVisible"
      width="60%"
      @close="imageViewerVisible = false"
    >
      <img :src="currentImage" class="full-image" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: [
        {
          title: "称量、取样、加样",
          imageFolder: "weighing",
          products: [
            { name: "采样袋", price: null, image: "采样袋.png" },
            { name: "称量纸", price: null, image: "称量纸.png" },
            { name: "胶头滴管", price: null, image: "胶头滴管.png" },
            { name: "镊子", price: null, image: "镊子.png" },
            { name: "塑料滴管", price: null, image: "塑料滴管.png" },
            { name: "洗耳球", price: null, image: "洗耳球.png" },
            { name: "药刮", price: null, image: "药刮.png" },
            { name: "药勺", price: null, image: "药勺.png" },
          ]
        },
        {
          title: "反应、连接、冷凝",
          imageFolder: "reaction",
          products: [
            { name: "三通接头", price: null, image: "三通接头.png" },
            { name: "乳胶管", price: null, image: "乳胶管.png" },
            { name: "冷凝管", price: null, image: "冷凝管.png" },
            { name: "分馏头", price: null, image: "分馏头.png" },
            { name: "升降台", price: null, image: "升降台.png" },
            { name: "双排管", price: null, image: "双排管.png" },
            { name: "反应瓶", price: null, image: "反应瓶.png" },
            { name: "反应管", price: null, image: "反应管.png" },
          ]
        },
        {
          title: "加热、降温",
          imageFolder: "heating",
          products: [
            { name: "刚玉舟", price: null, image: "刚玉舟.png" },
            { name: "坩埚", price: null, image: "坩埚.png" },
            { name: "坩埚钳", price: null, image: "坩埚钳.png" },
            { name: "温度计", price: null, image: "温度计.png" },
            { name: "温湿度计", price: null, image: "温湿度计.png" },
            { name: "石英舟", price: null, image: "石英舟.png" },
            { name: "蒸发皿", price: null, image: "蒸发皿.png" },
            { name: "酒精灯", price: null, image: "酒精灯.png" },
          ]
        },
        {
          title: "量器",
          imageFolder: "measuring",
          products: [
            { name: "三角烧瓶", price: null, image: "三角烧瓶.png" },
            { name: "容量瓶", price: null, image: "容量瓶.png" },
            { name: "烧杯", price: null, image: "烧杯.png" },
            { name: "移液管", price: null, image: "移液管.png" },
            { name: "量杯", price: null, image: "量杯.png" },
            { name: "量筒", price: null, image: "量筒.png" },
          ]
        },
        {
          title: "密封、搅拌、塞子",
          imageFolder: "sealing",
          products: [
            { name: "反口胶塞", price: null, image: "反口胶塞.png" },
            { name: "封口膜", price: null, image: "封口膜.png" },
            { name: "玻璃塞", price: null, image: "玻璃塞.png" },
            { name: "生料带", price: null, image: "生料带.png" },
            { name: "磁力搅拌子", price: null, image: "磁力搅拌子.png" },
            { name: "磁吸棒", price: null, image: "磁吸棒.png" },
            { name: "试管塞", price: null, image: "试管塞.png" },
          ]
        },
        {
          title: "容器、收纳",
          imageFolder: "container",
          products: [
            { name: "塑料托盘", price: null, image: "塑料托盘.png" },
            { name: "塑料盒", price: null, image: "塑料盒.png" },
            { name: "塑料箱", price: null, image: "塑料箱.png" },
            { name: "搪瓷盘", price: null, image: "搪瓷盘.png" },
            { name: "试管", price: null, image: "试管.png" },
            { name: "试管架", price: null, image: "试管架.png" },
            { name: "防溅球", price: null, image: "防溅球.png" },
          ]
        },
        {
          title: "研磨、过滤、分离",
          imageFolder: "grinding",
          products: [
            { name: "不锈钢网筛", price: null, image: "不锈钢网筛.png" },
            { name: "层析柱", price: null, image: "层析柱.png" },
            { name: "玛瑙研钵", price: null, image: "玛瑙研钵.png" },
            { name: "石英棉", price: null, image: "石英棉.png" },
            { name: "过滤瓶", price: null, image: "过滤瓶.png" },
            { name: "陶瓷漏斗", price: null, image: "陶瓷漏斗.png" },
          ]
        },
        {
          title: "样品储存",
          imageFolder: "sample",
          products: [
            { name: "标签", price: null, image: "标签.png" },
            { name: "样品瓶", price: null, image: "样品瓶.png" },
            { name: "样品管", price: null, image: "样品管.png" },
            { name: "氟化桶", price: null, image: "氟化桶.png" },
            { name: "种子瓶", price: null, image: "种子瓶.png" },
            { name: "自封袋", price: null, image: "自封袋.png" },
            { name: "记号笔", price: null, image: "记号笔.png" },
            { name: "试剂瓶", price: null, image: "试剂瓶.png" },
          ]
        },
        {
          title: "移液、分液",
          imageFolder: "pipetting",
          products: [
            { name: "吸头盒", price: null, image: "吸头盒.png" },
            { name: "枪头", price: null, image: "枪头.png" },
            { name: "移液器架", price: null, image: "移液器架.png" },
            { name: "移液枪", price: null, image: "移液枪.png" },
          ]
        }
      ],
      imageViewerVisible: false, // 控制图片查看器显示
      currentImage: '', // 当前查看的图片地址
    };
  },
  methods: {
    // 打开图片查看器
    openImageViewer(image) {
      this.currentImage = image;
      this.imageViewerVisible = true;
    },
    // 关闭图片查看器
    handleClose(done) {
      this.imageViewerVisible = false;
      done();
    }
  }
};
</script>

<style scoped>
.category-title {
  text-align: center;  /* 使标题居中 */
  font-size: 20px;
  font-weight: bold;
  margin: 30px 0 20px;
  color: #333;
}

.product-card {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.product-image {
  width: 100%;
  height: 150px;
  object-fit: contain; /* 确保图片自适应，不被裁切 */
  margin-bottom: 10px;
  cursor: pointer;
}

.product-info h3 {
  font-size: 16px;
  font-weight: bold;
}

.price {
  color: #e74c3c;
  font-weight: bold;
  font-size: 24px;
}

/* 图片查看器的样式 */
.full-image {
  width: 100%;
  height: auto;
}
</style>
