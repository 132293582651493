<template>
  <div>
    <!-- 轮播图部分 -->
    <el-carousel
      ref="carousel"
      indicator-position="none"
      :interval="2000"
      :autoplay="true"
      style="height: 100vh;"
      @mouseenter.native="handleMouseEnter"
    >
      <el-carousel-item v-for="(item, index) in 3" :key="index" style="height: 100vh;">
        <div class="carousel-bg" :style="{ backgroundImage: 'url(' + require('@/assets/home/bg' + (index + 1) + '.jpg') + ')' }">
          <div class="carousel-content">
            <h1>科岚宁专业测试</h1>
            <div class="divider"></div>
            <p v-if="index === 0">为科研工作者提供高端专业的检测服务以及科研检测方案</p>
            <p v-if="index === 1">让每一个科研工作者，都能享受互联网时代做实验的便利！</p>
            <p v-if="index === 2">精准测试，助力创新，打造未来科技的坚实基石。</p>
            <el-button type="primary" @click="scrollToServices">了解更多</el-button>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 服务项目部分 -->
    <div ref="services" class="services">
      <h1 class="centered-text">项目服务</h1>
      <p class="centered-text">支持多种项目服务，右下角扫码联系</p>
      <div class="row-wrapper">
        <el-row :gutter="20">
          <el-col :span="isWideScreen ? 8 : 24" v-for="item in services" :key="item.name">
            <el-card class="service-card"  @click.native="navigateTo1(item.path)">
              <div class="service-icon">{{ item.icon }}</div>
              <div class="service-content">
                <h3>{{ item.name }}</h3>
                <p>{{ item.description }}</p>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 支付方式部分 -->
    <div class="payment-options">
      <h1 class="centered-text">多种支付方式</h1>
      <p class="centered-text">支持多种支付方式，特推课题组团体支付</p>

      <div class="centered-div">
  <el-row :gutter="36" justify="center" class="payment-row">
    <el-col 
      v-for="(item, index) in paymentMethods" 
      :key="item.name" 
      :span="isWideScreen ? 6 : 20">
      <div class="payment-block" :class="`color-${index + 1}`">
        <div class="payment-icon">{{ item.icon }}</div>
        <p class="payment-name">{{ item.name }}</p>
      </div>
    </el-col>
  </el-row>
</div>


      

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isWideScreen: true, // 用于判断宽屏或窄屏
      services: [
        {
          name: '材料测试',
          icon: '📱',
          description: 'X射线光电子能谱、透射电子显微镜、扫描电子显微镜、比表面及孔隙分析仪',
          path: '/materials',
        },
        {
          name: '模拟计算',
          icon: '💻',
          description: '分子动力学模拟、第一性原理、有限元模拟、量子化学',
          path: '/simulation',
        },
        {
          name: '试剂耗材',
          icon: '⚗️',
          description: 'MXene-单层Ti3C2,水分散液、羟基磷灰石、100nm-中空介孔二氧化钛微球',
          path: '/reagents',
        },
        {
          name: '仪器设备',
          icon: '🔧',
          description: '各种实验室仪器，包括电子显微镜、光谱分析仪、激光设备等',
          path: '/equipment',
        },
        {
          name: '科研绘图',
          icon: '🖼️',
          description: '论文封面图、论文摘要图、论文插画设计、论文排版设计',
          path: '/graphics',
        },
        {
          name: '联系我们',
          icon: '📞',
          description: '微信联系、电话联系、邮箱联系、右下角扫码联系我们',
          path: '/about',
        },
      ],
      paymentMethods: [
        { name: '现金支付', icon: '💵' },
        { name: '团体支付', icon: '👥' },
        { name: '预存支付', icon: '💳' },
        { name: '积分支付', icon: '🎖️' },
        { name: '公务支付', icon: '🛂' },
      ],
    };
  },
  methods: {
    handleMouseEnter() {
      this.$refs.carousel.handleMouseEnter = () => {};
    },
    scrollToServices() {
      this.$refs.services.scrollIntoView({ behavior: 'smooth' });
    },
    navigateTo1(path) {
    if (this.$route.path !== path) {
      this.$router.push(path); // 使用 Vue Router 进行页面跳转
      window.scrollTo(0, 0);
    }
  },
    updateScreenWidth() {
      this.isWideScreen = window.innerWidth > 768;
    },
  },
  mounted() {
    this.updateScreenWidth();
    window.addEventListener('resize', this.updateScreenWidth);
      // 进入首页时弹出通知
  this.$notify({
    title: '您好',
    message: '欢迎来到科岚宁专业测试平台，右下角扫码联系我们！',
    type: 'success',
    position: 'top-right',
  });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
  
};
</script>


<style scoped>
/* 轮播图部分 */
.carousel-bg {
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.carousel-content {
  text-align: center;
}

.carousel-content h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.divider {
  width: 80px;
  height: 3px;
  background-color: yellow;
  margin: 10px auto;
}

/* 服务项目部分 */
.services {
  padding: 20px;
  text-align: center;
}

.centered-text {
  text-align: center;
}

.service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  height: 200px;
  flex: 1 1 280px; /* 设置最小宽度为300px，并允许灵活适应屏幕变化 */
  max-width: 350px; /* 限制最大宽度为320px */
  margin: 10px; /* 为卡片设置适当的外边距 */
}


.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 48px;
  margin-bottom: 10px;
}

.service-content h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.service-content p {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}


/* 支付方式部分 */
/* 支付方式部分 */
.centered-div {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 100%; /* 确保容器占满父元素 */
  padding: 20px 0; /* 可选：调整容器的上下间距 */
}

.payment-row {
  display: flex;
  justify-content: center; /* 子元素居中 */
  flex-wrap: wrap; /* 在窄屏时允许换行 */
  gap: 20px; /* 增加每个卡片之间的间距 */
  max-width: 1000px; /* 设置最大宽度，防止卡片过大 */
  width: 100%;
}

.payment-block {
  width: 100%; /* 保证每个块在其列中占满宽度 */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
}

.payment-icon {
  font-size: 48px; /* 增加图标大小 */
  margin-bottom: 15px;
}

.payment-name {
  font-size: 18px; /* 增加字体大小 */
  font-weight: bold; /* 加粗字体 */
}

/* 调整每个卡片在竖屏下的宽度 */
@media (max-width: 768px) {
  .payment-row {
    gap: 10px; /* 窄屏时的间距 */
  }
  .payment-block {
    width: 100%; /* 在竖屏下每个卡片占80%宽度 */
    margin-bottom: 20px; /* 卡片之间的垂直间距 */
  }
  .el-col {
    display: flex;
    justify-content: center; /* 保证列内的卡片水平居中 */
  }
}

/* 宽屏适配 */
@media (min-width: 769px) {
  .payment-block {
    width: 100%; /* 宽屏下每个卡片占80%宽度 */
  }
}

/* 窄屏适配 */
@media (max-width: 768px) {
  .payment-row {
    gap: 10px; /* 窄屏时的间距 */
  }
  .payment-block {
    margin-bottom: 20px; /* 卡片之间的垂直间距 */
  }
}


.color-1 { background-color: #32d693; }
.color-2 { background-color: #32d6c8; }
.color-3 { background-color: #44a3e0; }
.color-4 { background-color: #446ce0; }
.color-5 { background-color: #8669ee; }

/* 窄屏适配 */
@media (max-width: 768px) {
  .payment-block {
    margin-bottom: 20px;
  }
}

.el-col {
    margin-bottom: 20px;
  }

/* 窄屏适配 */
@media (max-width: 768px) {
  .el-col {
    margin-bottom: 20px;
  }
}
.row-wrapper {
  width: 90%; /* 宽度为屏幕宽度的 80% */
  margin: 0 auto; /* 水平居中 */
}
</style>
