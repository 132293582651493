<template>
  <div>
    <p style="height: 60px;"></p>
    <!-- 控制宽度的 div -->
    <div style="max-width: 90vw; margin: 0 auto;">
      <!-- 高温炉专题 -->
      <div v-for="(equipmentCategory, index) in equipmentCategories" :key="index">
        <h2 class="category-title">{{ equipmentCategory.title }}</h2>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="6" v-for="(equipment, index) in equipmentCategory.equipments" :key="index">
            <el-card shadow="hover" class="product-card">
              <!-- 点击图片打开大图 -->
              <img :src="require(`@/assets/equipment/${equipment.folder}/${equipment.image}.png`)" 
                   :alt="equipment.name" class="product-image" @click="openModal(equipment.folder, equipment.image)" />
              <div class="product-info">
                <h3>{{ equipment.name }}</h3>
                <div class="price-wrapper">
                  <span class="sale-price">待定</span>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 模态框 -->
    <el-dialog :visible.sync="isModalVisible" width="80%" @close="closeModal" :before-close="handleBeforeClose">
      <!-- 设置模态框内容样式，确保图片按比例显示 -->
      <div class="modal-content">
        <img :src="modalImageSrc" alt="Enlarged Image" class="modal-image" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      equipmentCategories: [
        {
          title: '高温炉',
          equipments: [
            { name: '加长非标管式炉', image: '1', folder: 'hightemperaturefurnace' },
            { name: '烘箱', image: '2', folder: 'hightemperaturefurnace' },
            { name: '高温炉', image: '3', folder: 'hightemperaturefurnace' },
            { name: '高真空管式炉', image: '4', folder: 'hightemperaturefurnace' },
            { name: '一站式配套量身定制最佳方案', image: '5', folder: 'hightemperaturefurnace' },
            { name: '真空管式炉', image: '6', folder: 'hightemperaturefurnace' },
            { name: '冷水机', image: '7', folder: 'hightemperaturefurnace' },
            { name: '大型回转炉', image: '8', folder: 'hightemperaturefurnace' },
            { name: '倾斜回转管式炉', image: '9', folder: 'hightemperaturefurnace' },
            { name: '高真空管式炉', image: '10', folder: 'hightemperaturefurnace' },
            { name: '升降炉', image: '11', folder: 'hightemperaturefurnace' },
            { name: '高真空滑轨管式炉', image: '12', folder: 'hightemperaturefurnace' },
            { name: '立式管式炉', image: '13', folder: 'hightemperaturefurnace' },
            { name: '配件', image: '14', folder: 'hightemperaturefurnace' },
            { name: '真空烘箱', image: '15', folder: 'hightemperaturefurnace' },
            { name: '红外管式炉', image: '16', folder: 'hightemperaturefurnace' },
            { name: '真空气氛炉', image: '17', folder: 'hightemperaturefurnace' }
          ]
        },
        {
          title: '反应釜',
          equipments: [
            { name: '二氧化碳高压加氢制取甲醇装置', image: '1', folder: 'reactor' },
            { name: '可视窗反应釜', image: '2', folder: 'reactor' },
            { name: '一体式平行反应釜', image: '3', folder: 'reactor' },
            { name: '多通道固定床反应装置', image: '4', folder: 'reactor' },
            { name: '环氧乙烷管式固定床反应器', image: '5', folder: 'reactor' },
            { name: '微型反应釜（磁力搅拌）', image: '6', folder: 'reactor' },
            { name: '分体式平行反应釜', image: '7', folder: 'reactor' },
            { name: '快开反应釜', image: '8', folder: 'reactor' },
            { name: '磁力搅拌', image: '9', folder: 'reactor' },
            { name: '台式升降反应釜', image: '10', folder: 'reactor' },
            { name: '乙二醇加氢催化评价装置', image: '11', folder: 'reactor' },
            { name: '磁力耦合搅拌', image: '12', folder: 'reactor' },
            { name: '立式智能高压反应釜', image: '13', folder: 'reactor' },
            { name: '吸附剂-催化剂评价装置', image: '14', folder: 'reactor' },
            { name: '高温高压反应釜', image: '15', folder: 'reactor' },
            { name: '微型反应釜', image: '16', folder: 'reactor' },
            { name: '一体平行釜', image: '17', folder: 'reactor' },
            { name: '电动升降反应釜', image: '18', folder: 'reactor' },
            { name: '固定床反应装置', image: '19', folder: 'reactor' },
            { name: '二氧化碳吸附试用装置', image: '20', folder: 'reactor' },
            { name: '脱附脱硝评价装置', image: '21', folder: 'reactor' },
            { name: '全透明反应釜', image: '22', folder: 'reactor' }
          ]
        }
      ],
      isModalVisible: false,
      modalImageSrc: ''
    };
  },
  methods: {
    openModal(folder, image) {
      // 设置模态框图片的 URL
      this.modalImageSrc = require(`@/assets/equipment/${folder}/${image}.png`);
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    handleBeforeClose(done) {
      this.isModalVisible = false;
      done();
    }
  }
};
</script>

<style scoped>
.category-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 30px 0;
  color: #333;
}

.product-card {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* 鼠标悬浮时的效果 */
.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.product-image {
  width: 100%;
  height: 150px;
  object-fit: contain; /* 确保图片自适应，不被裁切 */
  margin-bottom: 10px;
  cursor: pointer;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.product-info h3 {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.price-wrapper {
  margin-top: 10px;
}

.sale-price {
  color: #ff5722;
  font-size: 18px;
  font-weight: bold;
}

/* 修改模态框内容的样式 */
.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

/* 确保图片自适应模态框 */
.modal-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 5px;
}
</style>
