<template>
  <div>
    <p style="height: 60px;"></p>
    <!-- 控制宽度的 div -->
    <div style="max-width: 90vw; margin: 0 auto;">
      <div v-for="(topic, index) in topics" :key="index">
        <h2 style="text-align: center; margin-bottom: 20px; font-weight: bold;">{{ topic.title }}</h2>
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :sm="12"
            :md="6"
            v-for="(product, index) in topic.products"
            :key="index"
          >
            <el-card shadow="hover" class="product-card">
              <!-- 添加点击事件，点击时打开图片查看器 -->
              <img
                :src="require(`@/assets/materials/${product.image}.png`)"
                :alt="product.name"
                class="product-image"
                @click="openImageViewer(require(`@/assets/materials/${product.image}.png`))"
              />
              <div class="product-info">
                <h3>{{ product.name }}</h3>
                <div class="price-wrapper">
                  <span class="original-price">¥{{ product.originalPrice }}</span>
                  <span class="sale-price">¥{{ product.salePrice }}</span>
                </div>
                <!-- 如果是促销产品显示标签 -->
                <span v-if="product.isOnSale" class="sale-label">促销</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    
    <!-- 图片查看器，放在根元素之外 -->
    <el-dialog :visible.sync="imageViewerVisible" width="60%" :before-close="handleClose">
      <img :src="currentImage" class="full-image" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topics: [
        {
          title: "同步辐射",
          products: [
            { name: "同步辐射（每样）", originalPrice: 3500, salePrice: 3000, image: 1, isOnSale: true },
            { name: "同步辐射数据分析（每元素）", originalPrice: 1500, salePrice: 1200, image: 2, isOnSale: true },
          ],
        },
        {
          title: "显微成像",
          products: [
            { name: "场发射扫描电镜（SEM）（样）", originalPrice: 200, salePrice: 150, image: 3, isOnSale: true },
            { name: "场发射透射电镜（TEM）（样，含Mapping）", originalPrice: 800, salePrice: 600, image: 4, isOnSale: true },
            { name: "球差校正透射电镜（HAADF-STEM/AC-STEM）（小时）", originalPrice: 3500, salePrice: 3000, image: 5, isOnSale: true },
            { name: "原子力显微镜（样）", originalPrice: 500, salePrice: 350, image: 6, isOnSale: true },
          ],
        },
        {
          title: "结构分析",
          products: [
            { name: "X射线衍射仪（XRD）（样）", originalPrice: 120, salePrice: 100, image: 7, isOnSale: true },
            { name: "原位X射线衍射仪（In situ XRD）", originalPrice: 0, salePrice: "面议", image: 8, isOnSale: true },
            { name: "傅里叶红外光谱仪（FTIR）（样）", originalPrice: 80, salePrice: 50, image: 9, isOnSale: true },
            { name: "固/液体核磁（NMR）（样）", originalPrice: 300, salePrice: 250, image: 10, isOnSale: true },
            { name: "拉曼光谱仪（Raman）（样）", originalPrice: 150, salePrice: 100, image: 11, isOnSale: true },
            { name: "原位拉曼光谱仪（In situ Raman）", originalPrice: 0, salePrice: "面议", image: 12, isOnSale: true },
            { name: "原位红外", originalPrice: 0, salePrice: "面议", image: 13, isOnSale: true },
            { name: "吡啶红外（样）", originalPrice: 500, salePrice: 450, image: 14, isOnSale: true },
            { name: "紫外光电子能谱（UPS）（样）", originalPrice: 500, salePrice: 450, image: 15, isOnSale: true },
            { name: "电子自旋共振波谱仪（ESR/EPR）（样）", originalPrice: 350, salePrice: 300, image: 16, isOnSale: true },
            { name: "原位电子自旋共振波谱仪（ESR/EPR）", originalPrice: 0, salePrice: "面议", image: 17, isOnSale: true },
            { name: "紫外可见近红外光谱（UV/VIS/NIR）（样）", originalPrice: 150, salePrice: 120, image: 18, isOnSale: true },
          ],
        },
        {
          title: "成分分析",
          products: [
            { name: "X射线光电子能谱（XPS）", originalPrice: 250, salePrice: "50/元素", image: 19, isOnSale: true },
            { name: "等离子体光谱仪（ICP-OES）", originalPrice: 200, salePrice: 150, image: 20, isOnSale: true },
            { name: "X射线荧光光谱（XRF）", originalPrice: 200, salePrice: 100, image: 21, isOnSale: true },
            { name: "质谱仪（MS）", originalPrice: 200, salePrice: 150, image: 22, isOnSale: true },
            { name: "气相色谱-质谱联用（GC-MS）", originalPrice: 400, salePrice: 300, image: 23, isOnSale: true },
            { name: "高效液相色谱（HPLC）", originalPrice: 500, salePrice: 450, image: 24, isOnSale: true },
            { name: "穆斯堡尔谱仪", originalPrice: 2500, salePrice: 2000, image: 25, isOnSale: true },
            { name: "气相色谱（GC）", originalPrice: 300, salePrice: 250, image: 26, isOnSale: true },
            { name: "俄歇电子能谱（AES）", originalPrice: 300, salePrice: 250, image: 27, isOnSale: true },
          ],
        },
        {
          title: "物性分析",
          products: [
            { name: "全自动物理吸附仪（BET）", originalPrice: 300, salePrice: 200, image: 28, isOnSale: true },
            { name: "化学吸附仪（TPD/TPR/TPO）", originalPrice: 500, salePrice: 450, image: 29, isOnSale: true },
            { name: "热重-质谱联用（TG-MS）", originalPrice: 400, salePrice: 350, image: 30, isOnSale: true },
            { name: "高效凝胶色谱（GPC）", originalPrice: 500, salePrice: 450, image: 31, isOnSale: true },
            { name: "接触角测试（CA）", originalPrice: 300, salePrice: 220, image: 32, isOnSale: true },
            { name: "Zeta电位及纳米粒度分析仪（DLS）", originalPrice: 150, salePrice: 100, image: 33, isOnSale: true },
            { name: "差示扫描量热仪（DSC）", originalPrice: 350, salePrice: 300, image: 34, isOnSale: true },
            { name: "高温灼烧/热处理气氛炉(温度可达1600°C)", originalPrice: 500, salePrice: 450, image: 35, isOnSale: true },
            { name: "材料球磨制备", originalPrice: 400, salePrice: 300, image: 36, isOnSale: true },
          ],
        },
      ],
      imageViewerVisible: false, // 控制图片查看器显示
      currentImage: '', // 当前查看的图片地址
    };
  },
  methods: {
    // 打开图片查看器
    openImageViewer(image) {
      this.currentImage = image;
      this.imageViewerVisible = true;
    },
    // 关闭图片查看器
    handleClose(done) {
      this.imageViewerVisible = false;
      done();
    }
  }
};
</script>

<style scoped>
.product-card {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* 鼠标悬浮时的效果 */
.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.product-image {
  width: 100%;
  height: 150px;
  object-fit: contain; /* 确保图片自适应，不被裁切 */
  margin-bottom: 10px;
  cursor: pointer;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.product-info h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.price-wrapper {
  display: flex;
  align-items: center;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  margin-right: 5px;
}

.sale-price {
  color: #e74c3c;
  font-weight: bold;
  font-size: 24px;
}

.sale-label {
  background-color: #e74c3c;
  color: #fff;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 4px;
  margin-top: 10px;
}

/* 图片查看器的样式 */
.full-image {
  width: 100%;
  height: auto;
}
</style>
