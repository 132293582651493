<template>
  <div class="simulation-container">
    <p style="height: 60px;"></p>
    <!-- 第一张图片 -->
    <el-card class="simulation-card" shadow="always">
      <div slot="header" class="clearfix">
        <span>第一性原理</span>
      </div>
      <img :src="require('@/assets/simulation/1.png')" alt="第一性原理" class="simulation-image"/>
      <div class="description">
        这是基于量子力学的计算方法，主要通过计算电子结构来预测物质的性质。
      </div>
    </el-card>

    <!-- 第二张图片 -->
    <el-card class="simulation-card" shadow="always">
      <div slot="header" class="clearfix">
        <span>分子动力学模拟</span>
      </div>
      <img :src="require('@/assets/simulation/2.png')" alt="分子动力学模拟" class="simulation-image"/>
      <div class="description">
        分子动力学模拟通过模拟分子的运动来预测物质的动态行为，适用于液体、气体等复杂系统。
      </div>
    </el-card>

    <!-- 第三张图片 -->
    <el-card class="simulation-card" shadow="always">
      <div slot="header" class="clearfix">
        <span>有限元模拟</span>
      </div>
      <img :src="require('@/assets/simulation/3.png')" alt="有限元模拟" class="simulation-image"/>
      <div class="description">
        有限元模拟用于分析结构和力学行为，将复杂问题分解为简单的有限单元，广泛应用于工程设计中。
      </div>
    </el-card>

    <!-- 第四张图片 -->
    <el-card class="simulation-card" shadow="always">
      <div slot="header" class="clearfix">
        <span>量子化学</span>
      </div>
      <img :src="require('@/assets/simulation/4.png')" alt="量子化学" class="simulation-image"/>
      <div class="description">
        量子化学是研究原子和分子行为的学科，通过量子力学方法计算分子结构、反应和性质。
      </div>
    </el-card>
    <p style="height: 60px;"></p>
  </div>
</template>

<script>
export default {
  name: 'SimulationCards'
}
</script>

<style scoped>
.simulation-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.simulation-card {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.simulation-image {
  width: 100%;
  height: auto;
  display: block;
  margin-top: 15px;
}

.description {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}
</style>
